@import '@/scss/variables.scss';@import 'bulma/sass/utilities/mixins.sass';header.header-main.is-full {
  margin-bottom: 40px;
}

#featured-pages {
  position: relative;

  .image {
    img {
      opacity: 0;
      transition: opacity 0.5s;

      &.is-visible {
        opacity: 1;
      }
    }
  }

  .box-container {
    z-index: 1;
    left: 0;
    right: 0;
    position: absolute;
    transform: translateY(50%);
    bottom: 0;

    @include tablet {
      right: 50%;
      margin-right: $column-gap;
    }

    .box {
      padding: 0;

      .carousel {
        padding-bottom: 70px;

        .carousel-item {
          width: 100%;
          padding: 42px 32px 0 28px;

          @include tablet {
            padding: 42px 70px 0 48px;
          }
        }

        .flickity-page-dots {
          padding-left: 28px;
          justify-content: flex-start;

          @include tablet {
            padding-left: 48px;
          }
        }
      }
    }
  }
}

#map-component {
  height: 100vh;
  position: relative;
  margin-left: -$column-gap;
  margin-right: -$column-gap;
  z-index: 1;

  @include tablet {
    height: 680px;
  }

  @include desktop {
    margin-left: calc(-1 * $gap);
    margin-right: calc(-1 * $gap);
  }

  .map {
    width: 100%;
    height: 100%;

    @include fullhd {
      border-radius: $radius-large;
    }

    .leaflet-tile-pane {
      filter: invert(0.9);
    }

    .leaflet-div-icon {
      border: 0;
      background: transparent;
      color: $primary;

      &:hover,
      &.is-active {
        color: $white;

        svg circle {
          fill: $primary;
        }
      }
    }

    .leaflet-control-attribution {
      background: rgba(255, 255, 255, 0.8);
      border-radius: $radius 0 0 0;
      padding: 2px 8px 1px;

      a {
        color: $grey-dark;
      }

      @include fullhd {
        border-radius: $radius $radius 0 0;
        right: $radius-large;
      }
    }
  }

  .box {
    position: absolute;
    top: auto;
    left: 20px;
    padding: 34px 40px 42px;
    background: $primary;
    color: $white;
    z-index: 500;
    right: 20px;
    bottom: 80px;

    @include tablet {
      bottom: 60px;
      right: auto;
      width: calc(0.5 * (100% - 2 * $column-gap));
    }

    @include desktop {
      left: 110px;
      width: calc(0.3333333337 * (100% - 2 * $gap - 4 * $column-gap));
    }

    .button {
      transform: none;
      right: 30px;

      .icon {
        transform: none;
      }
    }

    &:hover {
      .button {
        transform: scale(0.9);

        .icon {
          transform: scale(1.3);
        }
      }
    }
  }
}

#grid {
  .columns {
    flex: 1;

    @include tablet {
      width: 100%;
    }

    &.is-vertical {
      flex-direction: column;
    }
  }

  .column {
    @include tablet {
      display: flex;
    }

    .box {
      width: 100%;
      height: 100%;
    }

    .is-size-2 {
      overflow-wrap: anywhere;
    }
  }

  .is-stamp {
    flex: none;
  }
}
